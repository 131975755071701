.container {
    border-radius: var(--border-radius);
    padding: 20px;
}
.paper {
    padding: 20px;
}

.content {
    padding: 15px;
}

.title {
    font-size: 24px;
    font-weight: 500;
}

.input {
    border: none;
    font-size: 18px;
}
.inputBlock {
    border: solid 1px #e0e0e0 !important;
    border-radius: var(--border-radius) !important;
}

.label {
    margin-left: 10px;
    margin-top: 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: var(--color-secondary-darker);
}

.btnDelete {
    align-self: flex-end;
}
@media (max-width: 960px) {
    .contentBtn {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .logout {
        margin-bottom: 10px !important;
    }
}





