.container {
    color: #2e416b;
    background-color: #fff;
    cursor: pointer;
    width: 100%;
    height: 120px;
    transition: all 0.3s;
    flex-direction: column;
}

.text {
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    line-height: 1;
}

.container:hover {
    color: #395286;
    border-color: #395286;
    transform: scale(1.1);
}

.delete {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.edit {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
}

.isDraggedOver {
    background-color: #e0e0e0;
}

.btnValidate {
    position: fixed !important;
    bottom: 20px !important;
    right: 100px !important;
    z-index: var(--zIndex-navBar);
}

.deleteIcon:hover {
    transform: scale(1.2);
}

.containerDropbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.lightText {
    color: var(--color-grey-2);
}

@media (hover) {
    .container:hover .edit,
    .container:hover .delete {
        display: block;
    }
}

@media (max-width: 800px) {
    .contentValidation {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
}
