@import '../../../../_css/variables/variables.css';

.contentHome {
    overflow-y: hidden;
    overflow-x: hidden;
}

.backgroundCircle {
    background: radial-gradient(46.25% 46.25% at 50% 50%, rgba(230, 103, 82, 0.2) 0%, rgba(230, 103, 82, 0) 100%) no-repeat;
    background-size: 43% 43%;
    background-position: top center;
}
.home {
    font-family: 'Roboto', 'sans-serif';
}

.homeHeaderImg {
    max-width: 75px;
}

.section {
    position: relative;
    min-height: 500px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    background-size: cover;
    background-attachment: fixed;
}

.sectionOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.sectionContent {
    padding: 60px 48px;
    width: 100%;
    max-width: 1200px;
    text-align: center;
    color: #fff;
    z-index: 2;
}

.textBlack {
    color: #555 !important;

    svg {
        color: #555 !important;
    }
}

.textWhite {
    color: #fff !important;

    svg {
        color: #fff !important;
    }
}

.sectionTitle {
    font-size: 54px;
    line-height: 81px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 42px;
}

.sectionTitle1 {
    margin-bottom: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@keyframes fadeInDelayed {
    from {
        opacity: 0;
    }

    35% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.sectionText {
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    margin-top: 42px;
    margin-bottom: 42px;
}

.sectionBtn, .sectionBtnSmall {
    cursor: pointer;
    margin: auto;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: all 0.3s;
    overflow: hidden;

    svg {
        font-size: 42px !important;
    }
}

.sectionBtnBorderWhite {
    border: solid 1px rgba(255, 255, 255, 0.7);

    &:hover {
        border-color: #FFF;
    }
}

.sectionBtnBorderBlack {
    border: solid 1px rgba(0, 0, 0, 0.3);

    &:hover {
        border-color: #555;
    }
}

.sectionMoreBtn1 {
    transform: translateY(25px);
}

.sectionMoreBtn2 {
    transform: translateY(-25px);
}

.video {
    width: 100%;
}

.sectionFirst {
    justify-content: flex-start;
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
}

.sectionFirst .sectionContent {
    padding: 35px 48px 0;
}

.sectionFirst .sectionBtn {
    margin-bottom: 40px;
    z-index: 2;
}

.sectionContent2 {
    width: 100%;
    flex: 1;
    z-index: 2;
}

.sectionContent2Inner {
    max-width: 700px;
    line-height: normal;
    font-size: 36px;
    padding-top: calc(260 / 908 * 100vh);
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 50vw;
}

@media (max-height: 720px) {
    .sectionContent2Inner {
        padding-top: 50px;
    }
}

@media (max-width: 1320px) {
    .sectionContent2Inner {
        margin: auto;
        padding-top: 50px;
        text-align: center;
    }
}

.sectionImage {
    width: 100%;
    max-width: 1000px;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
}

.sectionCta {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.7);
    font-weight: bold;
    font-size: 24px;
    text-decoration: none !important;
    color: #FFF !important;
    transition: all 0.3s;
}

@media (hover) {
    .sectionCta:hover {
        transform: scale(1.05);
    }
}
