.contentHeader {
    width: 100vw;
    align-items: center;
    height: 100%;
    background: white;
    z-index: var(--zIndex-navBar) !important;
}

.logo{
    width: 100%;
    margin-left: 20px;
}

.containerIcon {
    justify-self: flex-end !important;
    margin-right: 40px;
}

@media (min-width: 960px) {
    .container {
        display: none;
    }

}