:root {
    --color-primary: #e66752;
    --color-primary-darker: #be422e;
    --color-secondary: #435780;
    --color-secondary-darker: #2b3141;
    --color-tertiary: #ffa34d;
    --color-tertiary-alt: rgba(254, 220, 8, 0.71);
    --color-quaternary: #f67575;
    --color-event-tag: #e2fcfc;

    --color-grey-0: #f3f3f3;
    --color-grey-1: #e0e0e0;
    --color-grey-2: #bababa;
    --color-grey-3: #7b7b7b;
    --color-grey-4: #464646;

    --color-text: #2b3141;
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-on-primary: #fff;

    --color-primary-bg: #fff;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92A2BA;
    --color-secondary-bg: #F2F7FA;
    --color-firstLvl-bg: #f3f3f3;
    --color-secondLvl-bg: #F5F6FA;
    --color-secondLvl-dark-bg: #DCE0E9;
    --color-thirdLvl-bg: #FFF;

    --color-warning: var(--color-tertiary);
    --color-error: #FF3E1D;
    --color-delete: #FF3E1D;

    --color-border: #DFE3E6;
    --color-border-dark: #9b9b9b;
}
