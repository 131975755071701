.ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    margin: -20px;
}

a {
    color: var(--color-text);
    text-decoration: none;

    .li {
        margin: 0 20px;
        padding: 12px 0;
        border-bottom: 4px solid transparent;
        cursor: pointer;
        font-weight: 500;
        font-size: 20px;
    }
}

.liLoginIn {
    margin: 0 20px;
    padding: 12px 0;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
}

.li:hover,
.active {
    border-bottom: 4px solid var(--color-primary) !important;
}

a {
    text-decoration: none;
    .button {
        color: white;
        background-color: var(--color-primary);
        padding: 14px 40px;
        border-bottom: none;
        border-radius: var(--border-radius);
        font-size: 18px;
    }
}

.button {
    color: white;
    background-color: var(--color-primary);
    padding: 14px 40px;
    border-bottom: none;
    border-radius: var(--border-radius);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
}

.button:hover {
    outline: none !important;
    border: none !important;
}

.isMobile {
    color: white;
    border: solid 2px var(--color-primary-darker);
    border-radius: var(--border-radius);
    background-color: var(--color-primary-darker);
    margin: 0px;
}
