.title {
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--color-secondary-darker);
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.5px;
    color: var(--color-secondary-darker);
}

.dialog {
}