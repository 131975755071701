.container {
    align-items: stretch;
}

.icon {
    width: calc(50% - 20px);
    border-radius: 20px;
    border: 1px solid lightgrey;
    margin: 10px;
}

.icon:hover {
    transform: scale(1.02);
    border: 1px solid var(--color-secondary);
    cursor: pointer;
}

@media (max-width: 550px) {
    .container {
        flex-direction: column;
    }

    .icon {
        padding: 20px 10px;
        width: 100%;
    }
}
