:root {
    --color-primary: #e66752;
    --color-primary-darker: #be422e;
    --color-secondary: #435780;
    --color-secondary-darker: #2b3141;
    --color-tertiary: #ffa34d;
    --color-tertiary-alt: rgba(254, 220, 8, 0.71);
    --color-quaternary: #f67575;
    --color-event-tag: #e2fcfc;

    --color-grey-0: #f3f3f3;
    --color-grey-1: #e0e0e0;
    --color-grey-2: #bababa;
    --color-grey-3: #7b7b7b;
    --color-grey-4: #464646;

    --color-text: #2b3141;
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-on-primary: #fff;

    --color-primary-bg: #fff;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92A2BA;
    --color-secondary-bg: #F2F7FA;
    --color-firstLvl-bg: #f3f3f3;
    --color-secondLvl-bg: #F5F6FA;
    --color-secondLvl-dark-bg: #DCE0E9;
    --color-thirdLvl-bg: #FFF;

    --color-warning: var(--color-tertiary);
    --color-error: #FF3E1D;
    --color-delete: #FF3E1D;

    --color-border: #DFE3E6;
    --color-border-dark: #9b9b9b;
}
:root {
    --header-height: 120px;
    --header-height-mobile: 90px;
    --sidebar-width: 380px;
    --sidebar-width-closed: 20px;

    --xs: 5px;
    --sm: 10px;
    --md: 20px;
    --lg: 50px;
    --xl: 100px;

    --bp-xl: 1200px;
    --bp-lg: 992px;
    --bp-md: 768px;
    --bp-sm: 576px;
    --bp-xs: 400px;

    --app-width: 1200px;

    --border-radius: 8px;
    --input-height: 36px;
}
:root {
    --zIndex-navBar: 300;
    --zIndex-scenario-topBar: 200;
    --zIndex-scenario-sideBar: 250;
    --zIndex-home: 290;
    --zIndex-MobileMenu: 10000;
}
html,
body {
    padding: 0;
    margin: 0;
}
button:focus {
    outline: 0;
}
button:hover {
    cursor: pointer;
}
a:hover {
    cursor: pointer;
}
table {
    padding: 0;
    border-spacing: 0;
}
input {
    border: none;
}
input:focus {
     outline: 0;
 }
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
input,
label,
select,
button,
textarea {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
}
/* Remove the stupid outer glow in Webkit */
textarea:focus,
input:focus {
    outline: 0;
}
/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input,
textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/* File Uploads
-----------------------------------------------*/
input[type=file] {

}
/* Search Input
-----------------------------------------------*/
/* Make webkit render the search input like a normal text field */
input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: border-box;
}
/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
    display: none;
}
/* Buttons
-----------------------------------------------*/
button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
    /* Fix IE7 display bug */
    overflow: visible;
    width: auto;
}
/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
    padding: 0;
    border: 0;
    background: none;
}
/* Textarea
-----------------------------------------------*/
textarea {
    /* Move the label to the top */
    vertical-align: top;

    /* Turn off scroll bars in IE unless needed */
    overflow: auto;
}
/* Selects
-----------------------------------------------*/
select {

}
select[multiple] {
    /* Move the label to the top */
    vertical-align: top;
}
.flex_center_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.flex_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.flex_row_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flex_column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
}
.flex-2 {
    -webkit-box-flex: 2;
        -ms-flex: 2 1;
            flex: 2 1;
}
.flex-3 {
    -webkit-box-flex: 3;
        -ms-flex: 3 1;
            flex: 3 1;
}
.flexWrap_wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
}
.justifyContent_center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.justifyContent_flexEnd {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.justifyContent_spaceBetween {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.alignItems_center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.alignItems_flexStart {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.alignItems_flexEnd {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.p_5 {
    padding: 5px;
}
.p_10 {
    padding: 10px;
}
.p_20 {
    padding: 20px;
}
.p_30 {
    padding: 30px;
}
.p_25 {
    padding: 25px;
}
.p_50 {
    padding: 50px;
}
.pb_0 {
    padding-bottom: 0 !important;
}
.pb_10 {
    padding-bottom: 10px;
}
.pb_15 {
    padding-bottom: 15px;
}
.pb_20 {
    padding-bottom: 20px;
}
.pt_10 {
    padding-top: 10px;
}
.pt_20 {
    padding-top: 20px;
}
.pt_50 {
    padding-top: 50px;
}
.pl_7 {
    padding-left: 7px;
}
.pl_10 {
    padding-left: 10px;
}
.pl_20 {
    padding-left: 20px;
}
.pr_10 {
    padding-right: 10px;
}
.pr_50 {
    padding-right: 50px;
}
.ph_20 {
    padding-left: 20px;
    padding-right: 20px;
}
.ph_30 {
    padding-left: 30px;
    padding-right: 30px;
}
.pv_2 {
    padding-top: 2px;
    padding-bottom: 2px;
}
.pv_5 {
    padding-top: 5px;
    padding-bottom: 5px;
}
.pv_10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pv_15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.pv_20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.pv_30 {
    padding-top: 30px;
    padding-bottom: 30px;
}
.pt_0 {
    padding-top: 0 !important;
}
.link {
    font-weight: 600;
    text-decoration: none;
    color: #e66752;
    color: var(--color-primary);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
.link:hover {
        color: #be422e;
        color: var(--color-primary-darker)
    }
.m_0 {
    margin: 0 !important;
}
.m_5 {
    margin: 5px !important;
}
.mh_10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.mh_30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}
.mb_2 {
    margin-bottom: 2px !important;
}
.mb_5 {
    margin-bottom: 5px !important;
}
.mb_10 {
    margin-bottom: 10px !important;
}
.mb_15 {
    margin-bottom: 15px !important;
}
.mb_20 {
    margin-bottom: 20px !important;
}
.mb_25 {
    margin-bottom: 25px !important;
}
.mb_30 {
    margin-bottom: 30px !important;
}
.mb_40 {
    margin-bottom: 40px !important;
}
.mb_50 {
    margin-bottom: 50px !important;
}
.mb_60 {
    margin-bottom: 60px !important;
}
.mb_80 {
    margin-bottom: 80px !important;
}
.mb_100 {
    margin-bottom: 100px !important;
}
.ml_5 {
    margin-left: 5px !important;
}
.ml_10 {
    margin-left: 10px !important;
}
.ml_15 {
    margin-left: 15px !important;
}
.ml_20 {
    margin-left: 20px !important;
}
.ml_30 {
    margin-left: 30px !important;
}
.ml_40 {
    margin-left: 40px !important;
}
.ml_60 {
    margin-left: 60px !important;
}
.mr_3 {
    margin-right: 3px !important;
}
.mr_5 {
    margin-right: 5px !important;
}
.mr_10 {
    margin-right: 10px !important;
}
.mr_15 {
    margin-right: 15px !important;
}
.mr_20 {
    margin-right: 20px !important;
}
.mr_30 {
    margin-right: 30px !important;
}
.mr_50 {
    margin-right: 50px !important;
}
.mr_60 {
    margin-right: 60px !important;
}
.mr_70 {
    margin-right: 70px !important;
}
.mt_2 {
    margin-top: 2px !important;
}
.mt_3 {
    margin-top: 3px !important;
}
.mt_5 {
    margin-top: 5px !important;
}
.mt_7 {
    margin-top: 7px !important;
}
.mt_10 {
    margin-top: 10px !important;
}
.mt_15 {
    margin-top: 15px !important;
}
.mt_18 {
    margin-top: 18px !important;
}
.mt_20 {
    margin-top: 20px !important;
}
.mt_25 {
    margin-top: 25px !important;
}
.mt_27 {
    margin-top: 27px !important;
}
.mt_30 {
    margin-top: 30px !important;
}
.mt_50 {
    margin-top: 50px !important;
}
.mt_80 {
    margin-top: 80px !important;
}
.mv_5 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.mv_30 {
    margin-top: 30px;
    margin-bottom: 30px;
}
.mv_10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.gap_5 {
    gap: 5px;
}
.gap_10 {
    gap: 10px;
}
.overflow_auto {
    overflow: auto;
}
.cursor_pointer {
    cursor: pointer;
}
.cursor_notAllowed {
    cursor: not-allowed;
}
.userSelect_none {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.position_relative {
    position: relative;
}
.position_absolute {
    position: absolute;
}
.transform_rotateMinus90 {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.transform_rotatePlus90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.display_inlineBlock {
    display: inline-block;
}
.display_none {
    display: none;
}
.display_flex, .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.text_normal {
    font-size: clamp(1rem, 2.5vw, 1.4rem);
    font-weight: 400;
}
.text_small_semiBold {
    font-size: 14px;
    font-weight: 600;
}
.text_small {
    font-size: clamp(0.8rem, 2.5vw, 1.1rem);
    font-weight: 400;
}
.text_error {
    color: #FF3E1D !important;
    color: var(--color-error) !important;
}
.text_warning {
    color: #ffa34d !important;
    color: var(--color-warning) !important;
}
.text_big {
    font-size: 18px;
}
.text_huge {
    font-size: 22px;
}
.textAlign_right {
    text-align: right;
}
.textAlign_center {
    text-align: center;
}
.fontWeight_400 {
    font-weight: 400;
}
.fontWeight_600 {
    font-weight: 600;
}
.fontWeight_800 {
    font-weight: 800;
}
.color_secondary {
    color: #435780;
    color: var(--color-secondary);
}
.text_italic {
    font-style: italic;
}
.scenario_text_small {
    font-size: 16px !important;
}
.scenario_text_medium {
    font-size: 20px !important;
}
.scenario_text_large {
    font-size: 24px !important;
}
* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    white-space: pre-wrap;
}
html {
    font-size: 62.5%;
}
body {
    font-family: Roboto, sans-serif;
    min-height: 100%;
    color: #2b3141;
    color: var(--color-text);
    background-color: #f3f3f3;
    background-color: var(--color-firstLvl-bg);
    font-size: 1.6rem;
    overflow-y: auto;
    line-height: 1.1;
}
hr {
    background-color: #DFE3E6;
    background-color: var(--color-border);
    height: 1px;
    border: none;
}
textarea {
    white-space: pre-wrap;
}
::-webkit-scrollbar {
    width: 10px;
    background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border: solid 1px transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto, Arial, sans-serif;
}
h2 {
    font-family: Roboto, sans-serif;
    font-size: clamp(2rem, 2.5vw, 6rem);
    font-weight: 300;
}
h4 {
    font-family: Roboto, sans-serif;
    font-size: clamp(2rem, 2.5vw, 3.4rem);
    color: #435780;
    color: var(--color-secondary);
    font-weight: 500;
}
h5 {
    font-family: Roboto, sans-serif;
    font-size: clamp(1.8rem, 1.6vw, 3rem);
    color: #435780;
    color: var(--color-secondary);
    font-weight: 400;
}
.hidden {
    opacity: 0;
}
input,
textarea {
    font-family: Roboto, sans-serif;
}
.print-break-page {
    display: none;
}
@media screen {
    div.divFooter {
        display: none;
    }
}
@media print {
    * {
        float: none !important;
        overflow: initial !important;
    }

    thead,
    tfoot {
        display: none !important;
    }

    .no-print {
        display: none !important;
    }

    #force-print {
        opacity: 1 !important;
        height: 50%;
    }

    @page {
        margin: 5mm 15mm 5mm 0mm;
        size: auto;
    }

    .pageBreak {
        -webkit-column-break-after: page !important;
           -moz-column-break-after: page !important;
                break-after: page !important;
    }

    .avoidBreak {
        -webkit-column-break-inside: avoid !important;
           -moz-column-break-inside: avoid !important;
                break-inside: avoid !important;
    }

    .print,
    .print * {
        -webkit-print-color-adjust: exact;
        opacity: 1;
    }

    .print {
        left: 0;
        top: 0;
    }

    div.divFooter {
        position: fixed;
        bottom: 0;
        margin-top: 40px !important;
    }
    .sectionRender {
        width: 100vw;
    }

    .print-break-page {
        display: block;
        -webkit-column-break-after: page;
           -moz-column-break-after: page;
                break-after: page;
    }
}
.gallery_print {
    opacity: 0;
    height: 0;
}
.gallery_print .react-photo-gallery--gallery {
        width: 85% !important;
        -webkit-column-break-inside: avoid !important;
           -moz-column-break-inside: avoid !important;
                break-inside: avoid !important;
    }
.gallery_print .react-photo-gallery--gallery > div {
            width: 100% !important;
            /*img {
                max-width: 1mm !important;
            }*/
        }
.react-photo-gallery--gallery {
    -webkit-column-break-inside: avoid !important;
       -moz-column-break-inside: avoid !important;
            break-inside: avoid !important;
}
.react-photo-gallery--gallery > div {
        width: 100% !important;
        /*        img {
            width: 5mm !important;
        }*/
    }
