.container {
}

.title {
    font-size: clamp(2rem, 2.5vw, 3.4rem);
    font-weight: 500;
    color: var(--color-secondary-darker);
}

.scenarioName {
    color: var(--color-secondary);
}

.collaboratorsTitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.infoReviewContribution {
    background-color: white;
    z-index: 5;
    padding: 20px;
    right: 0;
    bottom: 30px;
    border-radius: var(--border-radius);
    min-width: 230px;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.2);
}

.selectCollaborator {
    position: absolute;
    width: 70%;
    background-color: white;
    border-radius: var(--border-radius);
    cursor: pointer;
    border: 1px solid var(--color-grey-1);
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
}

.hidden {
    visibility: hidden;
}

.itemSelect:not(:last-child) {
    border-bottom: var(--color-grey-1) 1px solid;
}

.itemSelect {
    padding: 2%;
    width: 90%;
    margin-left: 5%;
}

.itemSelect:hover {
    background-color: var(--color-grey-0);
}

@media (max-width: 960px) {
    .contentBtn {
        flex-direction: column-reverse;
    }

    .button {
        margin-top: 15px !important;
    }
}
