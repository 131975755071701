.title {
    font-size: clamp(2.2rem, 2.5vw, 4.8rem);
    color: var(--color-grey-2);
    font-weight: 500;
}

.addEvent {
    font-size: clamp(0.8rem, 2.5vw, 1.8rem);
}

.addEventIcon:hover {
    img {
        transform: scale(1.1);
    }
}

.line {
    border-left: 1px solid var(--color-secondary);
}

.dot {
    background-color: var(--color-secondary);
    position: absolute;
    left: -12px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
}

.dotAdd:hover {
    transform: scale(1.1);
}

.dotAdd {
    margin-left: 4px;
}

.userName {
    top: -18px;
    right: 7px;
    color: var(--color-grey-2);
    font-size: 1.5rem;
}

.addChapterBtn {
    top: 0;
    margin-top: 32px;
    height: 32px;
    width: 32px;
}

.addChapterBtnTooltip {
    top: -55px;
    left: -15px;
    border-radius: 20px;
    padding: 7px 5px;
    width: 100px;
    background-color: white;
    text-align: center;
}

.addChapterBtnTooltipContainer {
    z-index: 10;
}

.addChapterHoverBtn {
    position: absolute;
    top: 40px;
    bottom: 0;
    width: 20px;
    left: -10px;
    cursor: pointer;
}

.addChapterHoverBtn:hover {
    transition: all 0.4s;
    opacity: 1;
    z-index: 10;
}

.addEventZone:hover {
    border: 2px dotted var(--color-secondary);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    opacity: 1;
}

.addEventZone {
    transition: all 0.5s;
    cursor: pointer;
    opacity: 0;
    color: var(--color-secondary);
}

.container {
    width: 100%;
}
.input {
    width: 90%;
}

.btn {
    border: solid 2px var(--color-primary);
    background-color: var(--color-primary);
    color: white;
    padding: 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.btn:hover {
    transform: scale(1.01);
    box-shadow: none !important;
    background-color: var(--color-primary-darker);
}

.btnOutline:hover {
    transform: scale(1.01);
    box-shadow: none !important;
    background-color: var(--color-grey-1);
}

.btnOutline {
    border: solid 2px var(--color-primary);
    color: var(--color-primary);
    padding: 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

@media (max-width: 960px) {
    .container {
        flex-direction: column;
    }

    .contentBtns {
        margin-top: 10px;
    }
}
