.content {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    max-height: 600px;
    z-index: -1;
}

.container {
    max-height: 600px;
}

.overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(43, 49, 65, 0.4);
}
.contentImage {
    height: 100%;
    position: relative;
}
.images {
    height: 100%;
    width: 100%;
}


.btnDemo {
    border: solid 2px var(--color-primary) !important;
    background-color: white !important;
    color: var(--color-primary) !important;
    width: 299px;
    margin-top: 60px !important;
}

.text1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.5px;
    color: white;
    margin-bottom: 50px !important;
    margin-top: 60px !important;
}

.text2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25px;
    color: white;
    margin-bottom: 60px;
}

.sectionTwo {
    top: 10%;
    justify-content: center;
}

@media (max-width: 960px) {
    .topContainer {
        margin-bottom: 40px !important;
    }
    .image1 {
        visibility: visible;
    }
    .image3 {
        visibility: visible;
    }
    .image2 {
        object-fit: cover;
    }
    .sectionTwo {
        width: 80%;
        padding: 20px;
        height: 100%;
        top: 0;
    }
    .content {
        width: 100%;
    }
    .container {
        width: 100%;
    }
    .text1 {
        font-size: 20px;
        margin-bottom: 3px !important;
        margin-top: 5px !important;
        line-height: 1;
    }
    .text2 {
        font-size: 15px;
        margin-bottom: 0px;
        line-height: 1;
    }
    .btnDemo {
        width: 60%;
        margin: 0;
        padding: 2%;
        margin: 0 !important;
        margin-top: 10px !important;
    }
}