.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    font-family: Noto Serif, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 88px;
    text-align: center;
    color: var(--color-secondary-darker);
}

.subTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.25px;
    color: var(--color-secondary-darker);
    width: 33.33%;
    display: flex;
}

.textFirst {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-right: 15px;
    margin-left: 15px;
}
.textSecond {
    text-align: center;
    width: 100%;
    margin-right: 15px;
    margin-left: 15px;
}

.textThrid {
    text-align: center;
    width: 100%;
}

.contentImage {
    height: 100%;
}

.image {
    align-self: center;
    margin-right: 10px;
    margin-left: 10px;
}

.container {
    margin-bottom: 100px;
}


@media (max-width: 960px) {
    .content {
        flex-direction: column;
        justify-content: center;
        margin-top: 40px !important;
        margin-left: 0px;
        margin-right: 0px;
        align-items: center;
    }
    .subTitle {
        width: 90%;
    }
    .textFirst {
        margin: 10px;
        font-size: 20px;
        justify-content: center;
    }

    .textSecond {
        margin: 10px;
        font-size: 20px;
        justify-content: center;
    }

    .textThrid {
        justify-content: center;
        margin: 10px;
        font-size: 20px;
    }
    .title {
        font-size: 36px;
    }
    .contentImage {
        text-align: center;
    }
}
