.container {
    img {
        object-fit: cover;
        margin: 0 !important;
    }
}

.addImage {
    left: 50%;
    top: 10%;
    transform: translate(-50%, -50%);
    z-index: var(--zIndex-navBar);
}

.inputNbColumns {
    top: -20px;
    right: 50%;
    z-index: var(--zIndex-navBar);
    background-color: white;
}

.btn {
    opacity: 0.7 !important;
}
