.text {
    line-height: 1.2em;
}

.textSmall {
    font-size: 16px !important;
}

.textMedium {
    font-size: 20px !important;
}

.textLarge {
    font-size: 24px !important;
}

.text {
    line-height: 27px;
}
