.sidebar {
    z-index: var(--zIndex-scenario-sideBar);
    width: var(--sidebar-width);
    transition: 0.8s all;
}

.placeholder,
.container {
    width: var(--sidebar-width);
    height: calc(100vh - var(--header-height));
    transition: 0.2s opacity, 0.8s width;
}

.container {
    position: fixed;
    background-color: var(--color-grey-0);
}

.closeBtn {
    transition: 0.8s all;
    z-index: 10;
    left: calc(var(--sidebar-width) - 14px);
    right: 100vw;
    top: calc(var(--header-height) + 36px);
    position: fixed;
}

.close .closeBtn {
    left: 2px;
    right: 100vw;
}

.close .closeBtn img {
    transition: 0.8s left;
}

.close .closeBtn img {
    transform: rotate(180deg);
}

.close .placeholder,
.close .container {
    width: var(--sidebar-width-closed);
}

.close.sidebar {
    width: 0;
}

.close .container {
    opacity: 0;
    z-index: -10;
    padding-left: 0;
    padding-right: 0;
    width: 0;
}

@media (max-width: 960px) {
    .lastInput {
        margin-bottom: 50px !important;
    }

    .closeBtn {
        right: 0;
        left: unset;
    }

    .container {
        width: 100vw;
        height: calc(100vh - 90px);
    }
}

@media (max-width: 500px) {
    .closeBtn {
        right: 0;
        left: unset;
    }
    .closeImg {
        height: 35px !important;
    }
    .container {
        width: 100vw;
    }
}
