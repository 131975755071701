@import "../../../../../_css/variables/variables.css";

.root {
    transition: all 0.2s;
    line-height: 1.5 !important;
}

.root:hover {
    transform: scale(1.01);
}

.text.root,
.outlined.root,
.container.root {
    border-width: 2px !important;
    border: 3px solid transparent;
    font-family: "Roboto", sans-serif !important;
    font-size: 1.4rem !important;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1;
    padding: 13px 40px !important;
    text-align: center;
    border-radius: 8px !important;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
    display: inline-block;
    box-shadow: none;
}

.outlined.root.disabled,
.container.root.disabled {
    background-color: lightgrey !important;
}

.container:hover {
    box-shadow: none !important;
}

.outlined.root {
    border: solid;
}

.container.primary.root,
.container.secondary.root {
    color: white;
}

.container.primary.root {
    background-color: var(--color-primary);
}

.container.secondary.root {
    background-color: var(--color-secondary);
}

.container.primary.root:hover {
    background-color: var(--color-primary-darker);
}

.container.secondary.root:hover {
    background-color: var(--color-secondary);
}

.outlined.primary.root {
    border-color: var(--color-primary);
}

.text.primary.root,
.outlined.primary.root {
    color: var(--color-primary);
}

.outlined.secondary.root {
    border-color: var(--color-secondary);
    color: var(--color-secondary);
}

.outlined.label,
.container.label {
    font-family: Roboto, Arial, sans-serif;
    /*font-size: clamp(1.2rem, 5vw, 2.4rem);*/
    font-weight: 600;
}

.container.containerForDelete {
    color: var(--color-delete);
}

.container.containerForDelete.contained {
    color: #fff;
    background-color: var(--color-delete);
}

.container.sizeSmall {
    min-height: auto;
}

.outlined.root.noLeftBorder,
.container.root.noLeftBorder {
    border-radius: 0 40px 40px 0 !important;
}

.outlined.root.small,
.container.root.small {
    padding: 0 20px !important;
}

.outlined.label.small,
.container.label.small {
    font-size: clamp(1.2rem, 2.5vw, 1.8rem);
}

.bgWhite {
    background-color: white !important;
}

@media (max-width: 1300px) {
    .outlined.root,
    .container.root {
        padding: 7px 20px !important;
        font-size: 1.2rem;
    }

}

@media (max-width: 900px) {
    .text.root,
    .outlined.root,
    .container.root {
        padding: 3px 10px !important;
        font-size: 1rem;
    }
}
