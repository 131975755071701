.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--color-secondary-darker);
    margin-bottom: 10%;
}

.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.5px;
    color: var(--color-secondary-darker);
}

.image {
    margin-bottom: 5%;
}

.btn {
    width: 80%;
}
