.container {
    width: 100%;
}

.title {
    font-size: 20px;
    color: var(--color-primary);
}

.textSideBar {
    font-size: 20px !important;
}

.checkbox span {
    font-size: 16px !important;
}
