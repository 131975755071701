.input {
    background-color: white;
}

.results {
    z-index: var(--zIndex-home);
    background-color: #fff;
    box-shadow: 0px 2px 8px -2px rgb(0 0 0 / 10%);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.result {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
}

.result:hover {
    background-color: var(--color-grey-1);
    cursor: pointer;
}
