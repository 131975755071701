@import "../../../../_css/variables/variables.css";

.container {
    background-color: white;
    border-radius: 8px;
    padding: 10px 20px;
    border: 1px solid var(--color-grey-1);
}

.noBorder {
    border: 1px solid white;
}

.whiteTheme {
    background-color: white;
}

.label {
    font-size: 1.8rem;
    color: var(--color-secondary-darker);
    margin-bottom: 2px;
    padding-top: 5px;
}

.text {
    font-size: 2rem;
}

.required {
    color: var(--color-error);
}