@import '../../../_css/variables/variables.css';

.container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-secondLvl-dark-bg);
    padding: 10px 20px;
    z-index: 10;
}