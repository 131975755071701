.content {
    background-color: white;
    border-radius: 16px;
    padding: clamp(10px, 5vw, 50px);
    box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.1);
    min-height: 300px;
}

.separator {
    height: 1px;
    width: 100%;
    background-color: lightgrey;
    margin: 20px 0;
}

.collaboratorsTitle {
    font-size: clamp(1.2rem, 2.5vw, 1.8rem);
    color: var(--color-grey-3);
    font-weight: 500;
}

.email {
    font-size: clamp(1.2rem, 2.5vw, 1.4rem);
    font-weight: 500;
    color: var(--color-secondary-darker);
}

.btnDelete{
    color: var(--color-grey-2) !important;
}

.settingsCollaboratorDialog {
    padding: 10% !important;
}

.title {
    overflow-wrap: break-word;
}

.updatedAt {
    font-size: 17px;
}

@media (max-width: 1200px) {
    .container {
        padding: 10px;
    }
}

@media (max-width: 800px) {
    .content {
        min-height: 200px;
    }
}
