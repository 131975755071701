@import "../../../../_css/variables/variables.css";

.container {
    position: relative;
}

.input {
    min-height: var(--input-height);
    padding: 5px 0;
    flex: 1;
    font-size: 2rem;
    width: 100%;
    transition: all 0.3s;
    color: var(--color-secondary-darker);
}

.input::placeholder {
    color: var(--color-grey-2);
}

.inputWithEndIcon {
    padding-right: 30px;
}

.endIcon {
    position: absolute;
    top: 5px;
    right: 8px;
}

.error {
    font-size: 14px;
    color: var(--color-error);
    padding: 3px 0;
    min-height: 25px;
}

.textAreaNoResize {
    resize: none;
}