.containerHover {
    border: 1px solid var(--color-primary);
    border-radius: 5px;
}

.actions {
    right: 5px;
    top: 5px;
    border-radius: 5px;
    background-color: var(--color-primary);
    z-index: 250;
    opacity: 0.7;
}

.settings {
    display: none !important;
}

@media (max-width: 960px) {
    .settings {
        display: block !important;
    }
}
