.container {
    color: #2E416B;
    background-color: #FFF;
    border-radius: 5px;
    border: dashed 3px #2E416B;
    cursor: pointer;
    width: 120px;
    height: 120px;
    padding: 10px;
    transition: all 0.3s;
    flex-direction: column;
}

.text {
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    line-height: 1;
}

.container:hover {
    color: #395286;
    border-color: #395286;
    transform: scale(1.1);
}

.warning {
    color: var(--color-warning);
    font-size: 12px;
    margin-top: 4px;
}

.queue {
    text-align: center;
    margin-top: 12px;
    color: var(--color-grey-3);
    font-size: 12px;
}
