.container {
    color: var(--color-grey-3);
    font-weight: 400;
    font-size: 20px;
}

.isSelected,
.container:hover {
    border-bottom: 3px solid var(--color-primary);
}
