.currentItem {
    margin-top: 10px;
    margin-right: 15px;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    line-height: 1;
    cursor: pointer;
}

.item {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    margin-right: 15px;
    cursor: pointer;
}

.icon {
    margin-top: 8px;
    margin-right: 15px;
}

.list {
    list-style: none;
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.container {
    font-weight: 400;
    font-size: clamp(1.4rem, 2.5vw, 1.6rem);
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-secondary);
    margin-bottom: 10px;
}

/*@media (max-width: 460px) {
    li {
        display: none;
    }

    li:last-child {
        display: flex;
    }

    li:nth-last-child(2) {
        display: flex;
    }

    ul {
        flex-direction: column;
        align-items: flex-start;
    }
}*/
