.collaboratorTitle {
    font-size: large;
    color: var(--color-grey-3);
    font-weight: 500;
}

.column {
    font-size: large !important;
}

@media (max-width: 960px) {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .row {
        height: 100%;
        margin-top: 30px;
    }
    .column {
        flex-direction: column;
        padding: 10px;
    }
    .btnDelete {
        margin-top: 20px !important;
    }
}
