.content {
    border: solid 2px var(--color-secondary-darker);
    border-radius: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 90px;
    padding-right: 90px;
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 10px;
}

.text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.5px;
    color: var(--color-secondary-darker);
    width: 100%;
}

.image {
    width: 100%;
    display: flex;
    justify-content: center;
}

.image > img {
    width: 60px;
    position: absolute;
    top:-10%;
}

.container {
    margin-bottom: 70px;
}