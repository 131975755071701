.container {
    background-color: white;
    border-radius: calc(var(--border-radius) / 2);
    min-height: 65px;
}

.containerNotReview {
    border: #979291 2px dashed;
}

.containerPrivate {
    border: var(--color-warning) 2px solid;
}

.disable {
    background-color: var(--color-grey-2);
}

.date {
    font-weight: 500;
    color: var(--color-secondary);
    font-size: clamp(1.2rem, 2.5vw, 1.4rem);
}

.title {
    font-weight: 500;
    color: var(--color-secondary);
    font-size: clamp(1.6rem, 2.5vw, 2rem);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dot {
    background-color: var(--color-primary);
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

.privateCard {
    background-color: darkgrey;
}

.videosIcon {
    width: 20px !important;
    height: 20px !important;
}

.lockIcon {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 16px !important;
    height: 16px !important;
    color: var(--color-warning);
}

.sectionsContentCounts {
    float: right;
    font-size: 16px;
    gap: 16px;
}
