.textSmall {
    font-size: 16px !important;
}

.textMedium {
    font-size: 20px !important;
}

.textLarge {
    font-size: 24px !important;
}
