.header {
    z-index: var(--zIndex-scenario-topBar);
}

.container {
    height: 96px;
    width: calc(100% - var(--sidebar-width));
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.2);
    background-color: var(--color-grey-1);
    position: fixed;
    transition: width 0.8s;
}

.placeholder {
    height: 96px;
}

.sidebarClosed {
    width: 100%;
}

.btnAddChapter {
    margin-right: 15px;
}

.contentIcons {
    margin-right: 30px;
    cursor: pointer;
}

.item {
    font-size: 20px !important;
}

@media (max-width: 960px) {
    .icons {
        height: 25px;
        margin: 2px;
    }

    .labelHeader {
        /*font-size: 10px !important;*/
    }

    .btnAddChapter {
        margin-right: 0px;
        width: 100%;
    }

    .btnPreview {
        width: 80%;
    }

    .firstLineBtn {
        flex-direction: column;
        width: 100%;
    }

    .secondLineBtn {
        flex-direction: column-reverse;
        width: 100%;
        align-items: flex-end;
    }

    .contentIcons {
        margin-right: 8px !important;
        margin-top: 10px;
    }
}
