.image {
    align-self: flex-start;
    margin-bottom: 20px;
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: clamp(30px, 4vw, 48px);
    line-height: 40px;
    letter-spacing: -0.5px;
    color: #000000;
}

.text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: clamp(18px ,4vw , 24px) ;
    line-height: 28px;
    color: #000000;
}

.input {
    border: none;
}
.inputBlock {
    border: solid 1px #e0e0e0 !important;
    border-radius: var(--border-radius) !important;
}

.label {
    margin-left: 10px;
    margin-top: 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: var(--color-secondary-darker);
}

.dialog {
}

.subText {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #464646;
    text-align: center;
}

.agree {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: var(--color-secondary-darker);
}

.agree > a {
    color: #e66752;
}

.forgot {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #435780;
}

@media (max-width: 980px) {
    .firstRowForm {
        display: flex;
        flex-direction: column !important;
    }
}
