.photoContainer {
}

.photo {
    /*width: 100% !important;*/
}

.btnDelete {
}

.btnSwitch {
    left: 45px;
}

.iconBtn {
    height: 45px !important;
    width: 45px !important;
}