.container {
    /*min-height: 100vh;*/
}

.title {
    font-size: clamp(2.2rem, 2.5vw, 4.8rem);
    color: var(--color-secondary);
    font-weight: normal;
    display: flex;
    align-items: center;
}

.input {
    background-color: var(--color-grey-0);
    border-color: var(--color-grey-0);
}

.footerPlaceholder {
    height: 80px;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: var(--color-secondary);
    z-index: 300;
}

.isPublished {
    cursor: pointer;
    color: var(--color-primary);
}

.header {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.btn {
    visibility: hidden;
}

.isMobile {
    width: 100vw !important;
}

.btnCloseOrOpenSideBar {
    display: none;
}

@media (max-width: 960px) {
    .container {
        width: 100vw;
        margin-bottom: 100px;
        height: 100%;
    }

    .btnAddBlock {
        margin-bottom: 100px !important;
    }

    .contentInput {
        flex-direction: column;
    }

    .sideBarClose {
        position: absolute;
        right: 0;
        transition: all 0.8s;
        width: 100%;
        padding-top: 40px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .sideBarOpen {
        position: absolute;
        right: 100%;
        transition: all 0.8s;
    }

    .btnCloseOrOpenSideBar {
        display: block;
        transform: rotate(180deg);
    }

    .closeBtn {
        left: 0px;
    }

    .inputs {
        width: 100%;
        margin-left: 10px !important;
        margin-right: 0 !important;
    }

    .contentSections {
        width: 100%;
    }

    .footer {
        height: 120px;
        flex-direction: column-reverse;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .button {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}

@media (max-width: 500px) {
    .header {
        justify-content: start;
        align-items: start;
        flex-direction: column;
        gap: 24px;
    }
}
