.container {
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
}

.scenarioTitle {
    font-family: Noto Serif, serif;
    font-style: normal;
    font-weight: 600;
    font-size: clamp(40px, 4vw, 70px);
    line-height: 1.4em;
    color: var(--color-secondary-darker);
    overflow-wrap: break-word;
    width: 100%;
}

@media print {
    .scenarioTitle {
        font-size: 25px !important;
    }
    .scenarioHeader {
        width: 200% !important;
        display: flex;
        flex-direction: row;
    }

    .contentImage {
        width: 15% !important;
        padding: 2% !important;
        height: 100%;
    }

    .image {
        flex: 1;
    }

    .contentText {
        flex: 1;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.scenarioDescription {
    font-family: Roboto, Serif;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0.15px;
    color: var(--color-secondary-darker);
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
}

.contentText {
    flex: 1;
}

.draftPeriod {
    padding: 30px;
}

.contentImage {
    padding: 4%;
}

.scenarioHeader {
    border-bottom: solid 1px var(--color-grey-2);
    display: flex;
    margin-bottom: 30px;
}

.periodTitle {
    font-family: Roboto, Serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(32px, 5vw, 48px);
    line-height: 56px;
    letter-spacing: -0.5px;
    color: var(--color-secondary);
}

.eventDate {
    font-family: "Roboto", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-secondary-darker);
    align-items: flex-start;
}

.bullet {
    margin-top: 2px;
}

.eventTitle {
    font-family: "Roboto", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: var(--color-secondary);
    cursor: pointer;
}

.eventText {
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.15px;
    color: var(--color-secondary);
    margin-left: 40px;
    margin-bottom: 20px;
}

.sectionRender {
    margin-left: 40px;
}

.contentBookmark {
    height: 48px;
    background: var(--color-event-tag);
    border: 1px solid var(--color-secondary-darker);
    border-radius: 40px;
    padding: 2%;
}

.bookMarkIcon {
    height: 32px;
    width: 32px;
}

.image {
    max-height: 512px;
    max-width: 512px;
}

.btnFixedGoBack {
    position: fixed;
    left: 24px;
    height: 30px;
}

.btnEdit {
    margin-left: 30px !important;
    border: none;
    height: 20px !important;
}

.contentTitleAndEdit {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 20px;
}

.date {
    margin-right: 25px;
}

.btnEdit:hover {
    background-color: white !important;
}

@media (max-width: 960px) {
    .image {
        margin-right: 0 !important;
        max-height: 256px;
        max-width: 256px;
    }

    .scenarioHeader {
        /*width: 90vw;*/
    }
}
