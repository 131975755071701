.container {

}

.content {
    height: 120px;
}

.card {
    flex-basis: 33.33%;
    width: 33.33%;
}

.cardsContainer {
    margin: -20px;
}

@media (max-width: 1200px) {
    .cardsContainer {
        margin: -10px;
    }
}

@media (max-width: 1050px) {
    .card {
        flex-basis: 50%;
        width: 50%;
    }
    .container {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .card {
        flex-basis: 100%;
        width: 100%;
    }

    .content {
        height: 60px;
    }
}
