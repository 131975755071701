.sidebar {
    width: 350px;
    transition: all 0.8s;
}

.section {
    width: 350px;
    position: fixed;
    height: calc(100% - 80px);
    border-left: 2px solid lightgrey;
}

.sectionEditorForm {
    overflow-y: auto;
    background-color: var(--color-grey-1);
}
.btnCloseOrOpenSideBar {
    display: none;
}

@media (max-width: 960px) {
    .btnCloseOrOpenSideBar {
        display: block;
        position: absolute;
        top: 10px;
        height: 100%;
    }
    .opened {
        transform: rotate(180deg);
    }

    .sidebarOpen {
        position: absolute;
        left: 0;
        z-index: var(--zIndex-scenario-sideBar);
        background-color: white;
    }

    .sidebarClose {
        position: absolute;
        left: 100vw;
        background-color: white;
    }

    .sidebarClose > .section {
        padding: 0 !important;
    }
    .section {
        width: 100%;
        height: calc(100% - 120px);
    }
}
