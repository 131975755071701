.container {
    left: -50px;
    right: 50px;
    top: 0;
    opacity: 0;
    transition: all 0.4s;
    user-select: none;
    >input {

    cursor: default !important;
    }
}

.input {
    background-color: white;
}

.containerOpen {
    opacity: 1;
    left: -200px;
    cursor: text;
}
