@import "../../../../_css/variables/variables.css";

.contentHome {
    overflow-y: hidden;
    overflow-x: hidden;
}

.section {
    position: relative;
    min-height: 65vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    background-size: cover;
    background-attachment: fixed;
}

.sectionContent {
    text-align: center;
    color: var(--color-primary);
    font-size: 32px;
    font-weight: 700;
    z-index: 2;
}

.sectionSubContent {
    margin: 12px 0;
    text-align: center;
    color: var(--color-text);
    font-size: 24px;
    font-weight: 400;
    z-index: 2;
}

.sectionTitle {
    font-size: 54px;
    line-height: 81px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 42px;
}

.sectionHover {
    a {
        color: white;
    }
    a:hover {
        color: white;
    }
}
