.container {
    flex: 1;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    transition: all 0.3s;
    position: relative;
}

.img {
    padding: 5px;
    max-width: calc(100% - 10px);
}

.delete {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.check {
    position: absolute;
    top: 10px;
    left: 10px;
}


.selected {
    border: solid 1px green;
    box-shadow: 2px 2px 2px 2px rgb(55 168 74);
}

@media (hover) {
    .clickable:hover {
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
        transform: scale(1.02);
    }

    .container:hover .delete {
        display: block;
    }
}
