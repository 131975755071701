.title {
    font-family: Noto Serif, serif;
    font-style: normal;
    font-weight: 600;
    line-height: 88px;
    text-align: center;
    color: var(--color-secondary-darker);
    font-size: clamp(64px, 1.8vw, 70px);
    letter-spacing: -0.5px;
    margin-bottom: 80px;
}

.container {
    width: 100%;
    margin-bottom: 90px;
}

.number {
    border: solid 1px var(--color-primary);
    border-radius: 50% 50%;
    background-color: var(--color-primary);
    padding: 10px;
    color: white;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.numberInvert {
    border: solid 1px white;
    border-radius: 50% 50%;
    background-color: white;
    padding: 10px;
    color: var(--color-primary);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.subTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    color: var(--color-secondary-darker);
}

.subTitleInvert {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    color: white;
}

.image {
    width: 550px;
}

.images {
    width: 790px;
}
.img2 {
    width: 815px;
}

.text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2em;
    letter-spacing: 0.5px;
    color: var(--color-secondary-darker);
    margin-right: 150px;
    margin-left: 60px;
}

.textInvert {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2em;
    letter-spacing: 0.5px;
    color: white;
    margin-right: 160px;
    margin-left: 60px;
}

.bottomCard {
    background: linear-gradient(to top, var(--color-primary) 40%, #f3f3f3 30%);
    width: 100%;
    overflow: hidden;
}

.containerFirstCard {
    width: 100%;
}

.underImage {
    position: absolute;
    right: -30px;
    top: 20px;
    z-index: 60;
}

.onTopImage {
    position: absolute;
    top: -35px;
    right: 160px;
    z-index: 777;
}

.contentDoubleImage {
    min-height: 534px;
    width: 110%;
}

.backgroundCirle {
    max-width: 1200px;
    position: absolute;
}

.dashedLine1 {
    height: 310%;
    border-left: 2px dashed #9ec0d9;
    position: absolute;
    left: 3.7%;
    top: 40%;
}

.dashedLine2 {
    height: 180%;
    border-left: 2px dashed #9ec0d9;
    position: absolute;
    left: 3.5%;
    top: 30%;
}

.dashedLine3 {
    height: 100%;
    border-left: 2px dashed #9ec0d9;
    position: absolute;
    left: 3.5%;
    top: 8%;
}

.card {
    margin-top: 80px;
}

.card3 {
    margin-top: 170px;
}

.solidLine {
    position: absolute;
    height: 200%;
    left: 3.7%;
    top: 40%;
    border-left: 3px solid #9ec0d9;
}

.bigImage {
    margin-top: -20%;
}



@media (max-width: 1420px) {
    .contentSingleImage {
        position: absolute;
        right: -250px;
    }

    .card {
        height: 500px;
    }

    .text {
        max-width: 40%;
    }

    .dashedLine1,
    .solidLine,
    .dashedLine2 {
        left: 1.5%;
    }

    .dashedLine3 {
        top: 15%;
        left: 2%;
    }

    .dashedLine1 {
        height: 285%;
    }

    .underImage {
        visibility: hidden;
    }
    .contentDoubleImage {
        right: -300px;
    }
    .textInvert {
        min-width: 70%;
    }
}

@media (max-width: 1050px) {
    .dashedLine1 {
        height: 230%;
    }
    .solidLine {
        height: 180%;
    }
}
@media (max-width: 1015px) {
    .title {
        margin-bottom: 20px;
    }
    .card {
        margin-top: 40px;
    }
    .dashedLine1 {
        visibility: hidden;
    }
    .solidLine {
        visibility: hidden;
    }
    .dashedLine2 {
        visibility: hidden;
    }
    .dashedLine3 {
        visibility: hidden;
    }
    .images {
        width: 600px !important;
    }
}

@media (max-width: 900px) {
    .images {
        opacity: 0.2;
    }

    .image {
        opacity: 0.2;
    }

    .text {
        max-width: 80%;
    }
}

@media (max-width: 650px) {
    .dashedLine1 {
        height: 280%;
    }
    .dashedLine2 {
        height: 140%;
    }
    .text {
        max-width: 90%;
    }
}
@media (max-width: 550px) {
    .text {
        max-width: 100%;
    }
}

@media (max-width: 510px) {
    .solidLine {
        height: 80%;
    }
    .dashedLine1 {
        height: 240%;
    }
    .dashedLine2 {
        height: 110%;
    }
    .dashedLine3 {
        height: 100%;
        top: 30%;
    }
}
