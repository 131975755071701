.container {
    width: 100%;
    position: relative;
    color: var(--color-text );
}

.title {
    font-size: clamp(2rem, 2.5vw, 3.4rem);
    font-weight: 500;
    color: var(--color-secondary-darker);
}

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border: solid 1px purple;
    justify-content: space-between;
}

.iconCopy {
    cursor: pointer;
    align-self: center;
}

.text {
    margin-bottom: 30px;
}

.tooltip {
    position: absolute;
    top: 51%;
    right: -5px;
    background-color: lightgrey;
    border-radius: var(--border-radius);
    padding: 2%;
}
