.dropzone {
    border: 2px dotted var(--color-primary);
    border-radius: 5px;
}

.gif {
    height: 200px;
    width: 300px;
    position: absolute;
    cursor: pointer;
}

.title {
    font-size: 18px;
    font-weight: 500;
}

.link {
    color: var(--color-primary);
    text-decoration: underline;
}

.btnHidden {
    visibility: hidden;
}

.visibleBtn {
    visibility: visible;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.container {
    height: 220px;
}
