.containerHeader {
    height: 120px;
}

.content {
    height: 100%;
}

.btn {
    width: 180px;
}

.back {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
}

.filter {
    cursor: pointer;
}

.contentFilter {
    background-color: white;
    padding: 4%;
    border: solid var(--color-grey-2) 1px;
    left: -165px;
    border-radius: var(--border-radius);
    width: 200px;
    display: flex;
    flex-direction: column;
}

.itemFilter {
    padding: 10%;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
}

.itemFilter:hover {
    background-color: var(--color-grey-0);
}

.itemActive {
    background-color: var(--color-grey-3);
    color: white;

}

.itemFilter:not(:last-child) {
    border-bottom: 1px var(--color-grey-2) solid;
}

.numberFilters {
    background-color: var(--color-primary);
    color: white;
    padding: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 25px;
    left: -15px;
    font-weight: 600;
}

.icon {
    height: 20px;
}

.tag {
    font-size: large;
    font-weight: 600;
}
