@import '../../../../../_css/variables/variables.css';

.container {
    max-width: 100%;

    &:before, &:after {
        display: none !important;
    }

    .select {
        height: var(--input-height);
        box-sizing: border-box;
        border: solid var(--color-border) 1px;
        display: flex;
        align-items: center;
        padding: 0 30px 0 12px !important;
        font-size: 16px;
        border-radius: var(--border-radius) !important;

        &:focus {
            background-color: transparent !important;
        }
    }

    .icon {
        right: 6px;
    }
}

.disabled {
    color: rgba(0, 0, 0, 0.87) !important;

    &.icon {
        display: none;
    }
}
