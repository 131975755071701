@import '../../../../../_css/variables/variables.css';

.age {
    position: absolute;
    top: -20px;
    font-weight: bold;
    font-size: 14px;
}

.error {
    font-size: 14px;
    color: var(--color-error);
    padding: 3px 0;
    min-height: 25px;
}