.title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: clamp(30px, 1.8vw, 48px);
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--color-secondary-darker);
}

.card {
    border: solid 1px var(--color-secondary-darker);
    border-radius: var(--border-radius);
    height: 480px;
    padding: 64px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.h5 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: clamp(18px, 1.6vw, 24px);
    line-height: 28px;
    text-align: center;
    color: var(--color-secondary-darker);
    text-transform: uppercase;
}

.text {
    font-family: "Roboto", serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(22px, 1.6vw, 34px);
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--color-secondary-darker);
    margin-right: 20px;
    margin-left: 20px;
    height: 100px;
    display: flex;
    margin-bottom: 40px;
}

.images {
    margin-top: 40px;
}

.container {
    margin-bottom: 150px;
}

@media (max-width: 960px) {
    .content {
        display: flex;
        flex-direction: column;
    }

    .card {
        padding: 20px 12px;
        margin-bottom: 20px;
    }
    .container {
        margin-bottom: 60px;
    }
    .images {
        margin: 0;
    }
    .text {
        margin: 0;
    }
}
