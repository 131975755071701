@import '../../../../_css/variables/variables.css';

.container {
    position: relative;
}

.input {
    border-radius: var(--border-radius);
    border: solid var(--color-border) 1px;
    min-height: var(--input-height);
    padding: 5px 5px 5px 10px;
    flex: 1;
    font-size: 15px;
    width: 100%;
    transition: all 0.3s;

    &:focus {
        border-color: var(--color-primary);
        border-width: 2px;
        outline: var(--color-primary);
    }
}

.inputWithEndIcon {
    padding-right: 30px;
}

.endIcon {
    position: absolute;
    top: 5px;
    right: 8px;
}

.error {
    font-size: 14px;
    color: var(--color-error);
    padding: 3px 0;
    min-height: 25px;
    margin-left: 10px;
}

.textAreaNoResize {
    resize: none;
}