.toggleBlock {
    font-size: 13px;
    color: var(--color-text);
    cursor: pointer;
}

.toggle {
    height: 24px;
    width: 40px;
    border-radius: 12px;
    margin-right: 10px;
    background-color: var(--color-grey-2);
    position: relative;
    border: solid 1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
}

.toggle-label {
    position: absolute;
    right: 7px;
    top: 3px;
    font-size: 11px;
    -font-weight: 600;
}

.label {
    color: var(--color-secondary) !important;
    font-size: large;
}

.toggle-handle {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.3s;
}

.toggle-handle:hover {
    opacity: 0.9;
}

.toggleBlock_active {
    -font-weight: 600;
    color: var(--color-primary);
}

.toggleBlock_active .toggle {
    background-color: var(--color-primary) !important;
}

.toggleBlock_active .toggle-handle {
    left: 18px;
}

.toggleBlock_active .toggle-label {
    left: 7px;
    right: auto;
}
