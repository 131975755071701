.container {
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 30%;
    right: 0;
    z-index: var(--zIndex-MobileMenu);
    background-color: var(--color-primary);
    color: var(--color-text-on-primary);
    padding: 20px;
    text-align: center;
    transform: translateX(0);
    transition: all 0.4s ease-in-out;
    list-style-type: none;
}

.closed {
    transform: translateX(100vw);
}

.logo {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
}

.closeIcon {
    position: absolute !important;
    top: 10px;
    right: 20px;
}

.li {
    color: white;
    border: solid 2px var(--color-primary-darker);
    border-radius: var(--border-radius);
    background-color: var(--color-primary-darker);

}

@media (max-width: 1000px) {
    .container {
        right: 0;
    }
    .closeIcon {
        right: 50px;
    }
}

@media (max-width: 600px) {
    .container {
        left: 0;
        width: 100vw;
    }
    .closeIcon {
        right: 20px;
    }
}
