.container {
    min-height: 300px;
    max-height: 100vh;
    overflow-y: auto;
}

@media (max-width: 800px) {
    .container {
        padding: 20px;
    }
}