.container {
    position: relative;
}

.input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
}

.placeholder {
    width: 100%;
}
