.image {
    align-self: center;
}

.btnDelete {
    border-color: red !important;
}

.btnDelete:hover {
    background-color: red !important;
    color: white;
}

.text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: clamp(18px ,4vw , 24px);
    line-height: 28px;
    color: #000000;
    text-align: center;
    padding: 2%;
    margin-bottom: 30px;
}