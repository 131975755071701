.container {
    background-color: var(--color-grey-1);
    min-height: calc(100vh - var(--header-height));
    height: 100%;
    overflow: hidden;
}

.linkBack {
    padding-top: 100px;
    padding-bottom: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--color-primary);
    cursor: pointer;
}

.btnFixedGoBack {
    position: fixed !important;
    bottom: 20px;
    left: 20px;
    z-index: var(--zIndex-scenario-sideBar);
    border: solid 2px var(--color-primary);
    border-radius: var(--border-radius);
    padding: 10px;
    background-color: var(--color-primary);
    cursor: pointer;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btnScrollToTop {
    position: fixed !important;
    bottom: 20px;
    right: 100px;
    z-index: var(--zIndex-scenario-sideBar);
    border: solid 2px var(--color-primary);
    border-radius: 50%;
    padding: 10px;
    background-color: var(--color-primary);
    cursor: pointer;
    color: white;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: fixed;
    right: -30px;
}

@media (max-width: 960px) {
    .container {
        max-width: 100vw;
    }
    .body {
        width: 100%;
    }

}
