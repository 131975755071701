@import "../../../_css/variables/variables.css";

.container,
.wrapper,
.placeholder {
    height: var(--header-height);
}

.mobileContainerMenu {
    display: none;
}

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: var(--color-primary-bg);
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.2);
}

.logo {
    font-size: 28px;
    font-weight: 900;
    color: var(--color-primary);
    text-decoration: none;
    margin-right: 10px;
}

.slogan {
    margin: 0 10px;
    font-size: 20px;
    background-color: lightgrey;
}

.wrapper {
    position: fixed;
    margin: auto;
}

.separator {
    padding-right: 40px !important;
    border-right: 2px solid var(--color-grey-2);
    display: flex;
    align-items: center;
}

.logout {
    border: solid 2px var(--color-primary) !important;
    border-radius: var(--border-radius) !important;
    margin-left: 5% !important;
}

@media (max-width: 1000px) {
    .wrapper {
        display: none;
    }
    .mobileContainerMenu {
        display: flex;
        position: fixed;
        color: var(--color-primary);
        height: var(--header-height-mobile);
        z-index: 999;
    }
    .placeholder {
        height: var(--header-height-mobile);
    }

}
